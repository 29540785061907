import { action } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';
import RSVP from 'rsvp';

import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import MenuVendorController from 'mobile-web/controllers/menu/vendor';
import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import {
  isDelivery,
  HandoffMode,
  mapPostalCode,
  isDeliveryMode,
} from 'mobile-web/lib/order-criteria';
import { isTruthy } from 'mobile-web/lib/query-params';
import { isOk } from 'mobile-web/lib/result';
import { PreviousRoute } from 'mobile-web/lib/routing';
import { noop } from 'mobile-web/lib/utilities/_';
import isSome from 'mobile-web/lib/utilities/is-some';
import Channel from 'mobile-web/models/channel';
import HandoffModeModel from 'mobile-web/models/handoff-mode';
import LoyaltyAccountModel from 'mobile-web/models/loyalty-account';
import OrderSearchResultModel, {
  OrderSearchResultStatus,
} from 'mobile-web/models/order-search-result';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChannelService from 'mobile-web/services/channel';
import ContentService from 'mobile-web/services/content';
import DeviceService from 'mobile-web/services/device';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import { ProductClickFrom, RecommendationSource } from 'mobile-web/services/global-data';
import GroupOrderService from 'mobile-web/services/group-order';
import LoyaltyService, { LoadedLoyaltyData, LoyaltyResults } from 'mobile-web/services/loyalty';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import OrderItAgainService from 'mobile-web/services/order-it-again';
import PageScrollService, { PageScrollEvent } from 'mobile-web/services/page-scroll';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

export type Model = {
  vendor: Vendor;
  recentOrders?: OrderSearchResultModel[];
  loyalty: Array<{
    account: LoyaltyAccountModel | undefined;
    schemeName: string;
  }>;
  pageTitle: string;
};

export default class VendorRoute extends Route {
  // Service injections
  @service content!: ContentService;
  @service loyalty!: LoyaltyService;
  @service orderCriteria!: OrderCriteriaService;
  @service session!: SessionService;
  @service('vendor') vendorService!: VendorService;
  @service channel!: ChannelService;
  @service basket!: BasketService;
  @service('error') errorService!: ErrorService;
  @service storage!: StorageService;
  @service onPremise!: OnPremiseService;
  @service intl!: IntlService;
  @service analytics!: AnalyticsService;
  @service features!: FeaturesService;
  @service groupOrder!: GroupOrderService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service pageScroll!: PageScrollService;
  @service bootstrap!: BootstrapService;
  @service orderItAgain!: OrderItAgainService;
  @service device!: DeviceService;

  // Untracked Properties
  previousRoute: PreviousRoute = {
    label: 'Locations',
    route: 'vendor-search-results',
    models: [],
  };

  // Tracked Properties
  @alias('channel.current')
  currentChannel?: Channel;
  @readOnly('session.isLoggedIn')
  isLoggedIn!: boolean;

  // Lifecycle Methods
  constructor() {
    super(...arguments);

    this.pageScroll.on('beforechange', this, 'beforeChange');
  }

  willDestroy() {
    this.pageScroll.off('beforechange', this, 'beforeChange');
  }

  async setupController(
    controller: MenuVendorController,
    model: ModelForRoute<this>,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    // Required to test OnPremise in proxy mode, bc in proxy mode
    // we can only set cookies on an api call, not alongside a document response.
    // This makes an extra api call to simulate behavior in deploymed environments.
    const exp = controller.closedCheck
      ? OnPremiseExperience.ClosedCheck
      : controller.openCheck
      ? OnPremiseExperience.OpenCheck
      : OnPremiseExperience.Default;

    if (
      exp !== OnPremiseExperience.Default &&
      !this.onPremise.isEnabled &&
      (window.location.hostname === 'localhost' || this.device.isHybrid)
    ) {
      await this.session.setOnPremiseData(controller.tablePosRef, exp);
      window.location.reload();
    }

    if (this.onPremise.isEnabled) {
      // Reset any pre-existing onpremise state if
      // the `?handoff=X` query param is provided and is not DineIn
      // OR if the vendor slug changes
      //  (multiorder checks multiorderdata value; opencheck compares to null, which conveniently works in this case)
      const requestedHandoffisNotDineIn =
        controller.handoff && this.getSearchHandoffMode(controller.handoff) !== 'DineIn';

      const currentOnPremiseVendorSlug =
        this.onPremise.closedCheckData?.vendorSlug ?? this.onPremise.vendorSlugForOpenCheck;

      const vendorMismatch =
        currentOnPremiseVendorSlug && model.vendor?.slug !== currentOnPremiseVendorSlug;

      if (vendorMismatch || requestedHandoffisNotDineIn) {
        await this.onPremise.endOnPremise();
      }
    }

    // `pickup` and `curbside` are not valid handoff query params. Fix them.
    if (controller.handoff?.toLocaleLowerCase() === 'pickup') {
      controller.handoff = 'counterpickup';
    }
    if (controller.handoff?.toLocaleLowerCase() === 'curbside') {
      controller.handoff = 'curbsidepickup';
    }

    const isExternal = !this.storage.firstRouteName;

    // capture On Premise query params for analytics:
    const onPremiseQueryParam = controller.multiOrder // legacy QR code handling
      ? 'closedcheck'
      : controller.closedCheck
      ? 'closedcheck'
      : controller.openCheck
      ? 'opencheck'
      : undefined;
    const hasTableNumber = !!controller.tablePosRef;
    this.analytics.trackEvent(AnalyticsEvents.LandedOnVendorMenu, () => {
      const properties: AnyObject = {
        [AnalyticsProperties.ExternalNavigation]: isExternal,
        [AnalyticsProperties.HasTableNumber]: hasTableNumber,
      };
      if (onPremiseQueryParam) {
        properties[AnalyticsProperties.OnPremiseQueryParameter] = onPremiseQueryParam;
      }
      if (controller.handoff) {
        properties[AnalyticsProperties.HandoffQueryParameter] = controller.handoff;
      }
      return properties;
    });

    const effectiveHandoff = this.getEffectiveHandoff();
    const vendorMenuDefaultHandoffFeatureFlag =
      this.features.flags['vendor-menu-default-handoff-olo-43322'];
    const closeHandler =
      vendorMenuDefaultHandoffFeatureFlag &&
      !isDeliveryMode(effectiveHandoff) &&
      !isDeliveryMode(this.orderCriteria.bestVendorSupportedHandoffMode)
        ? () => {
            this.orderCriteria.updateSearchOrderCriteria(
              this.orderCriteria.bestVendorSupportedHandoffMode
            );
          }
        : noop;

    let isValidCriteria = true;
    if (controller.handoff !== undefined) {
      const handoffMode = this.getSearchHandoffMode(controller.handoff);
      isValidCriteria = this.orderCriteria.updateSearchOrderCriteria(handoffMode, closeHandler);
      controller.set('skipPreCheck', !isValidCriteria || this.basket.basket !== undefined);
    }
    if (
      vendorMenuDefaultHandoffFeatureFlag &&
      !this.orderCriteria.searchOrderCriteria &&
      (!this.orderCriteria.basketOrderCriteria || !isValidCriteria)
    ) {
      isValidCriteria = this.orderCriteria.updateSearchOrderCriteria(
        effectiveHandoff,
        closeHandler
      );
      controller.set('skipPreCheck', !isValidCriteria || this.basket.basket !== undefined);
    }

    if (
      isTruthy(controller.openCriteria) ||
      (this.vendorService.vendor?.settings.supportsDynamicPricing &&
        !this.orderCriteria.basketOrderCriteria)
    ) {
      this.orderCriteria.openModal({ componentNameInitiatedModal: 'Vendor Route' });
    }

    const validHandoffModes: HandoffModeModel[] = this.store
      .peekAll('handoff-mode')
      .filter(h => this.orderCriteria.isValidHandoffMode(h.type));
    const onlyDineInValid =
      validHandoffModes.length === 1 && validHandoffModes[0].type === 'DineIn';

    let experience =
      this.bootstrap.data?.onPremiseSessionData?.experienceType ?? OnPremiseExperience.Default;

    if (
      this.channel.settings?.redirectDineInToClosedCheck &&
      (onlyDineInValid || controller.handoff === 'dinein')
    ) {
      experience = OnPremiseExperience.ClosedCheck;
    }
    if (
      isSome(controller.multiOrder) ||
      isSome(controller.closedCheck) ||
      isSome(controller.openCheck) ||
      isSome(controller.tablePosRef) ||
      controller.handoff === 'dinein' ||
      onlyDineInValid
    ) {
      await this.onPremise.setClientOnPremise(experience, this.vendorService.vendor!.slug);
    }

    if (isSome(controller.clearOnPrem)) {
      await this.onPremise.endOnPremise();
    }

    next(this, () => {
      controller.set('multiOrder', undefined);
      controller.set('closedCheck', undefined);
      controller.set('tablePosRef', undefined);
      controller.set('clearOnPrem', undefined);
      controller.set('openCheck', undefined);
      controller.set('handoff', undefined);
      controller.set('openCriteria', undefined);
      controller.set('forceReload', undefined);
    });
  }

  async model(params: { vendor_slug: string }): Promise<Model> {
    const isLoggedIn = this.isLoggedIn;
    const vendor = await this.vendorService.ensureVendorLoaded(params.vendor_slug);

    if (this.groupOrder.isLocked) {
      this.groupOrder.lockGroupOrder(false);
    }

    this.channel.savedCurrentCountry = vendor.address.country?.toLowerCase();

    const loadedLoyaltyData: LoadedLoyaltyData[] = [];

    if (isLoggedIn) {
      const results: LoyaltyResults = await taskFor(this.loyalty.loadModel).perform(vendor.id);
      loadedLoyaltyData.push(...results.filter(isOk).map(r => r.value));
    }

    const loyalty = loadedLoyaltyData.map(data => ({
      account: data.account,
      schemeName: data.membership.schemeName,
    }));

    const recentOrders = this.session.isLoggedIn
      ? this.store
          .findAll('order-search-result')
          .then(results =>
            results.filter(
              r =>
                r.vendorSlug === params.vendor_slug && r.status === OrderSearchResultStatus.Complete
            )
          )
      : undefined;

    const visibleMostOrderedProducts = this.vendorService.sortAndFilterRecommendations(
      ProductClickFrom.MostOrdered,
      this.store.peekAll('product').filter(product => product.isMostOrdered)
    );
    let visibleForYouCount = 0;

    if (isLoggedIn) {
      await this.vendorService.ensureRecommendationCategoriesLoadedNoAnalytics();
      this.storage.wasLoggedIn = true;

      // tracking recommendation views regardless of whether any are visible so this shows missing recommendations
      const visibleRecentItems = this.vendorService.sortAndFilterRecommendations(
        ProductClickFrom.RecentItem,
        this.store.peekAll('product').filter(product => product.isRecentItem)
      );
      this.analytics.trackEvent(AnalyticsEvents.ViewRecommendations, () => ({
        [AnalyticsProperties.RecommendationSource]: RecommendationSource.RecentProduct,
        [AnalyticsProperties.RecommendationProductCount]: visibleRecentItems.length,
        [AnalyticsProperties.RecommendationProductIds]: visibleRecentItems.map(x => x.id),
      }));

      const visibleForYou = this.vendorService.sortAndFilterRecommendations(
        ProductClickFrom.ForYouCrossSell,
        this.store.peekAll('product').filter(product => product.isForYou)
      );
      this.analytics.trackEvent(AnalyticsEvents.ViewRecommendations, () => ({
        [AnalyticsProperties.RecommendationSource]: RecommendationSource.ForYouCrossSell,
        [AnalyticsProperties.RecommendationProductCount]: visibleForYou.length,
        [AnalyticsProperties.RecommendationProductIds]: visibleForYou.map(x => x.id),
        [AnalyticsProperties.RecommendationProductIdsTaggedMostOrdered]: visibleForYou
          .filter(p => visibleMostOrderedProducts.includes(p))
          .map(x => x.id),
      }));
      visibleForYouCount = visibleForYou.length;
    }

    if (visibleForYouCount === 0) {
      this.analytics.trackEvent(AnalyticsEvents.ViewRecommendations, () => ({
        [AnalyticsProperties.RecommendationSource]: RecommendationSource.MostOrdered,
        [AnalyticsProperties.RecommendationProductCount]: visibleMostOrderedProducts.length,
        [AnalyticsProperties.RecommendationProductIds]: visibleMostOrderedProducts.map(x => x.id),
      }));
    }

    if (!this.orderCriteria.criteria.isDefault) {
      const oc = this.orderCriteria.criteria;
      if (isDelivery(oc) && oc.deliveryAddress && !oc.deliveryAddress.id) {
        this.channel.savedCurrentCountry = mapPostalCode(oc.deliveryAddress.zipCode);
        const newAddress = await this.store.collectionAction('address', 'addDeliveryAddress', {
          ...oc.deliveryAddress,
          basketId: this.basket.basket?.id,
        });
        oc.deliveryAddress.id = newAddress.id;
      }
    }

    return RSVP.hash({
      vendor,
      loyalty,
      recentOrders,
      pageTitle: this.pageTitle(vendor),
    });
  }

  afterModel(model: ModelForRoute<this>) {
    this.session.trackVendorSlug = '';
    if (!this.onPremise.isEnabled) {
      this.storage.storeLocationHistory(model.vendor);
      this.storage.redirectFromHome = true;
    }
  }

  // Other Methods

  /**
   * Prevent the page from scrolling to the top when navigating from a quick-add
   * product modal back to the menu.
   */
  beforeChange(event: PageScrollEvent) {
    const { from, to } = event.transition;
    if (to?.name !== 'menu.vendor.index' || from?.name !== 'menu.vendor.products') {
      return;
    }

    const productId = from.params.product_id;
    if (isNone(productId)) {
      return;
    }

    const product = this.store.peekRecord('product', productId);

    if (!product || product.hasChoices) {
      return;
    }

    event.preventDefault();
  }

  async pageTitle(vendor: Vendor): Promise<string> {
    const menuTitle = await this.content.getEntry('MENU_TITLE', {
      VendorName: vendor.name,
      City: vendor.address.city,
      State: vendor.address.state,
    });

    return menuTitle ? menuTitle : vendor.name;
  }

  getEffectiveHandoff(): HandoffMode {
    return this.orderCriteria.basketOrderCriteria
      ? this.orderCriteria.basketOrderCriteria.handoffMode
      : this.orderCriteria.bestVendorSupportedHandoffMode;
  }

  getSearchHandoffMode(handoff: string): HandoffMode {
    switch (handoff.toLocaleLowerCase()) {
      case 'counterpickup':
        return 'CounterPickup';
      case 'curbsidepickup':
        return 'CurbsidePickup';
      case 'delivery':
        return 'MultiDelivery';
      case 'drivethru':
        return 'DriveThru';
      case 'dinein':
        return 'DineIn';
      default:
        return 'Unspecified';
    }
  }
  @action
  refreshVendorModel() {
    this.refresh();
  }
}
